html {
  .btn  {
    @apply flex;
    @apply w-full;
    @apply justify-center;
    @apply rounded-md;
    @apply bg-stone-600;
    @apply px-3;
    @apply py-2;
    @apply text-sm;
    @apply font-semibold;
    @apply leading-6;
    @apply text-white;
    @apply shadow-sm;

    &:hover {@apply bg-stone-500;}
    &:focus-visible {
      @apply outline;
      @apply outline-2;
      @apply outline-offset-2;
      @apply outline-stone-600;
    }
  }

  .link {
    @apply font-semibold;
    @apply leading-6;
    @apply text-stone-600;
    &:hover {
      @apply text-stone-500;
    }
  }

  .label {
    @apply block;
    @apply text-sm;
    @apply font-medium;
    @apply leading-6;
    @apply text-gray-900;
  }

  .input {
    @apply block;
    @apply w-full;
    @apply rounded-md;
    @apply border-0;
    @apply py-1.5;
    @apply text-gray-900;
    @apply shadow-sm;
    @apply ring-1;
    @apply ring-inset;
    @apply ring-gray-300;
    &::placeholder {
      @apply text-gray-400;
    }
    &:focus {
      @apply ring-2;
      @apply ring-inset;
      @apply ring-stone-600;
    }
    @apply sm:text-sm;
    @apply sm:leading-6;
  }

  .h2 {
    @apply text-lg;
    @apply font-medium;
    @apply text-gray-900;
  }

  .check-label {
    @apply ml-3;
    @apply min-w-0;
    @apply flex-1;
    @apply text-gray-500;
  }

  .check-label-comp {
    @apply ml-3;
    @apply text-sm;
    @apply text-gray-600;
  }

  .checkbox {
    @apply h-4;
    @apply w-4;
    @apply rounded;
    @apply border-gray-300;
    @apply text-stone-600;
    &:focus {
      @apply ring-stone-500;
    }
  }

}
